import { render, staticRenderFns } from "./ChargeAsOptionFields.vue?vue&type=template&id=652d1d52&scoped=true&"
import script from "./ChargeAsOptionFields.vue?vue&type=script&lang=js&"
export * from "./ChargeAsOptionFields.vue?vue&type=script&lang=js&"
import style0 from "./ChargeAsOptionFields.vue?vue&type=style&index=0&id=652d1d52&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "652d1d52",
  null
  
)

export default component.exports