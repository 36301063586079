(<template>
  <div class="option-fields">
    <sk-radio v-for="option in optionsList"
              :key="option.text"
              :item-name="itemName"
              :preselected-value="preselectedValue"
              :item-value="option.value"
              :item-text="option.text"
              class="option-fields__radio"
              @radiochanged="setOption" />
  </div>
</template>)

<script>
  export default {
    props: {
      itemName: {
        type: String,
        default: ''
      },
      preselectedValue: {
        type: [String, Boolean, Number],
        default: ''
      }
    },
    computed: {
      optionsList() {
        return [{
          value: true,
          text: this.$gettext('Yes')
        }, {
          value: false,
          text: this.$gettext('No')
        }];
      },
    },
    methods: {
      setOption(value) {
        this.$emit('optionchanged', value);
      }
    }
  };
</script>

<style scoped>
  .option-fields {
    margin: 0 -10px;
  }

  .option-fields__radio {
    margin: 0 10px;
  }
</style>
