(<template>
  <div class="option-fields">
    <sk-checkbox v-for="option in optionsList"
                 :key="option.key"
                 :preselected-value="preselectedValue[option.key]"
                 :item-text="option.text"
                 class="option-fields__checkbox"
                 @checkboxvaluechanged="option.method" />
  </div>
</template>)

<script>
  import helpers from '@/helpers';

  export default {
    props: {
      preselectedValue: {
        type: Object,
        default: () => {
          return {};
        }
      }
    },
    computed: {
      optionsList() {
        return [{
          key: 'booking',
          text: helpers.getJobInfo.jobManualFee(this, 'booking'),
          method: this.setOption('booking')
        }, {
          key: 'editing',
          text: helpers.getJobInfo.jobManualFee(this, 'editing'),
          method: this.setOption('editing')
        }, {
          key: 'travelBooking',
          text: helpers.getJobInfo.jobManualFee(this, 'travelBooking'),
          method: this.setOption('travelBooking')
        }];
      },
    },
    methods: {
      setOption(key) {
        return (value) => {
          return this.$emit('optionchanged', {key, value});
        };
      }
    }
  };
</script>

<style scoped>
  .option-fields {
    display: flex;
    margin: 0 -10px;
  }

  .option-fields__checkbox {
    width: auto;
    margin: 0 10px;
  }
</style>
