(<template>
  <div class="option-fields">
    <sk-radio v-for="option in optionsList"
              :key="option.text"
              :item-name="itemName"
              :preselected-value="preselectedValue"
              :item-value="option.value"
              :item-text="option.text"
              class="option-fields__radio"
              @radiochanged="setOption" />
  </div>
</template>)

<script>
  import helpers from '@/helpers';

  export default {
    props: {
      itemName: {
        type: String,
        default: ''
      },
      preselectedValue: {
        type: String,
        default: ''
      }
    },
    computed: {
      optionsList() {
        return [{
          value: 'in_person',
          text: helpers.getJobInfo.jobType('in_person', this)
        }, {
          value: 'videoroom',
          text: helpers.getJobInfo.jobType('videoroom', this)
        }, {
          value: 'phone',
          text: helpers.getJobInfo.jobType('phone', this)
        }, {
          value: 'video',
          text: helpers.getJobInfo.jobType('video', this)
        }];
      },
    },
    methods: {
      setOption(value) {
        this.$emit('optionchanged', value);
      }
    }
  };
</script>

<style scoped>
  .option-fields {
    margin: 0 -10px;
  }

  .option-fields__radio {
    margin: 0 10px;
  }
</style>
