<template>
  <div class="document-list">
    <a v-for="(file, index) in documents"
       :key="index"
       :href="file.link"
       :title="titleText"
       target="_blank"
       class="document-list__link">{{ `file ${file.id}` }}</a>
  </div>
</template>

<script>
  export default {
    props: {
      documents: {
        type: Array,
        default: () => []
      },
    },
    computed: {
      titleText() { return this.$gettext('Download file'); }
    }
  };
</script>

<style scoped>
  .document-list {
    display: flex;
    flex-direction: column;
  }

  .document-list__link {
    color: var(--color-primary);
    text-decoration: underline;
  }
</style>
