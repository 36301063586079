(<template>
  <div class="decision-tools">
    <div class="accordion__header--opened">
      <div class="default-accordion__header decision-tools__header">{{ $gettext('Confirmed decision to finance KS') }}</div>
    </div>
    <feedback-section :progress-active="progressActive"
                      :show-button="true"
                      :button-description="lastUpdatedText"
                      class="default-accordion__content decision-tools__content"
                      @save="save">
      <div class="decision-tools__table-wrapper">
        <div class="decision-tools__table">
          <div class="decision-tools__table-row">
            <div v-for="col in cols"
                 :key="col.id"
                 class="decision-tools__table-cell decision-tools__table-cell--col-header">
              <div class="decision-tools__cell">{{ col.title || '' }}</div>
            </div>
          </div>
          <div v-for="row in rows"
               :key="row.id"
               :class="row.classes"
               class="decision-tools__table-row">
            <div v-for="cell in row.cells"
                 :key="cell.id"
                 :class="{'decision-tools__table-cell--row-header': !!cell.title}"
                 class="decision-tools__table-cell">
              <template v-if="cell.condition !== undefined ? cell.condition : true">
                <div v-if="cell.title || cell.text"
                     class="decision-tools__cell decision-tools__cell--center"
                     :class="[{'expense-list': cell.file}]"
                     v-html="cell.title || cell.text">
                </div>
                <div v-if="cell.link && cell.linkName"
                     class="decision-tools__cell decision-tools__cell--center">
                  <a :href="cell.link"
                     target="_blank"
                     class="sk-btn sk-btn--white">{{ cell.linkName }}</a>
                </div>
                <div v-if="cell.component"
                     :class="cell.cellClasses"
                     class="decision-tools__cell">
                  <component :is="cell.component"
                             :documents="cell.documents"
                             :preselected-value="cell.preselectedValue"
                             :preselected-date="cell.preselectedDate"
                             :preselected-time="cell.preselectedTime"
                             :preselected-option="cell.preselectedOption"
                             :description="cell.description || ''"
                             :no-description="!cell.description"
                             :time-step="cell.timeStep"
                             :items-list="cell.itemsList"
                             :class="cell.classes"
                             :reg-exp="cell.regExp"
                             :item-name="cell.itemName"
                             :default-value="cell.defaultValue || '--'"
                             :placeholder="cell.placeholder"
                             :validation-name="cell.errorsName"
                             :max-length="cell.maxLength"
                             class="decision-tools__form-field"
                             @timepickervaluechanged="cell.onClick"
                             @optionchanged="cell.onClick"
                             @csvaluechanged="cell.onClick"
                             @datechanged="(value) => cell.onDateClick(value)"
                             @timechanged="(value) => cell.onTimeClick(value)"
                             @fieldvaluechanged="cell.onClick" />
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </feedback-section>
  </div>
</template>)

<script>
  import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
  import helpers from '@/helpers';
  import FeedbackSection
    from '@/components/assignment_components/one_assignment/interactive_info/pt_feedback/FeedbackSection';
  import YesNoOptionFields from './YesNoOptionFields';
  import ChargeAsOptionFields from './ChargeAsOptionFields';
  import BookingDataOptionFields from './BookingDataOptionFields';
  import BookingFeeOptionFields from './BookingFeeOptionFields';
  import DownloadDocuments from './DownloadDocuments';

  export default {
    components: {
      'feedback-section': FeedbackSection,
      // table parts
      'option-fields': YesNoOptionFields,
      'charge-as-option-fields': ChargeAsOptionFields,
      'booking-data-option-fields': BookingDataOptionFields,
      'booking-fee-option-fields': BookingFeeOptionFields,
      'documents-list': DownloadDocuments
    },
    data() {
      return {
        progressActive: false
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userCurrencyName',
        'userIsNotInterpreter'
      ]),
      ...mapGetters('JobDecisionStore', [
        'jobDemanderInfo',
        'jobSupplierInfo',
        'statement',
        'expenses'
      ]),
      ...mapState('OneAssignmentStore', {
        storeJobObj: ({storeJobObj}) => storeJobObj || {}
      }),
      ...mapState('JobDecisionStore', {
        form: ({form}) => form || '',
        // errors
        demanderDiscountErrorName: ({form}) => form.errors.demanderInvoiceDiscountPercentage.name,
        supplierDiscountErrorName: ({form}) => form.errors.supplierInvoiceDiscountPercentage.name,
        demanderDiscountErrors: ({form}) => form.errors.demanderInvoiceDiscountPercentage.errors,
        supplierDiscountErrors: ({form}) => form.errors.supplierInvoiceDiscountPercentage.errors,
      }),
      ...mapState('FeedbackStore', {
        lastUpdatedData: ({lastUpdatedData}) => lastUpdatedData?.job_decision,
        lastUpdatedJobData: ({lastUpdatedData}) => lastUpdatedData?.job_updated
      }),
      lastUpdatedText() {
        return helpers.feedbackInfo.lastUpdatedText({
          context: this,
          lastUpdatedSectionData: this.lastUpdatedData,
          lastUpdatedJobData: this.lastUpdatedJobData,
        });
      },
      jobId() {
        return this.$route.params.id;
      },
      statementId() {
        return this.storeJobObj.statementId;
      },
      assignmentRequirements() {
        return this.storeJobObj.interpretationRequirement || {};
      },
      jobStartTime() {
        return this.$moment(this.assignmentRequirements.startTime);
      },
      jobFinishTime() {
        return this.$moment(this.assignmentRequirements.finishTime);
      },
      cols() {
        return [{
          id: 'table_titles'
        }, {
          id: 'customer_feedback',
          title: this.$gettext('Customer feedback')
        }, {
          id: 'interpreter_feedback',
          title: this.$gettext('Interpreter feedback')
        }, {
          id: 'customer_decision',
          title: this.$gettext('Customer decision')
        }, {
          id: 'interpreter_decision',
          title: this.$gettext('Interpreter decision')
        }];
      },
      rows() {
        return [{
          id: 'comments',
          cells: this.commentsCells,
        }, {
          id: 'chargeablePayableValues',
          cells: this.chargeablePayableCells,
        }, {
          id: 'showedUp',
          cells: this.showedUpCells,
        }, {
          id: 'problems',
          cells: this.problemsCells
        }, {
          id: 'startTimes',
          cells: this.startTimeCells
        }, {
          id: 'delay',
          cells: this.delayCells
        }, {
          id: 'expanses',
          cells: this.expanseCells
        }, {
          id: 'finishTimes',
          cells: this.finishTimeCells
        }, {
          id: 'chargeAsOptions',
          classes: ['decision-tools__table-row--margin-top decision-tools__table-row--no-row-borders'],
          cells: this.chargeAsOptionCells
        }, {
          id: 'travelType',
          cells: this.travelTypeCell
        }, {
          id: 'travelDistances',
          cells: this.travelDistanceCells
        }, {
          id: 'travelTimes',
          cells: this.travelTimeCells
        }, {
          id: 'diets',
          cells: this.dietCells
        }, {
          id: 'bookingData',
          classes: ['decision-tools__table-row--margin-top decision-tools__table-row--no-borders decision-tools__table-row--no-row-borders'],
          cells: this.bookingDataCells
        }, {
          id: 'bookingFees',
          classes: ['decision-tools__table-row--no-row-borders'],
          cells: this.bookingFeeCells
        }];
      },
      commentsCells() {
        return [{
          id: 'comment',
          title: this.$gettext('Comment')
        }, {
          id: 'customer_feedback_comment',
          text: this.jobDemanderInfo.comment || ''
        }, {
          id: 'interpreter_feedback_comment',
          text: this.jobSupplierInfo.comment || ''
        }, {
          id: 'customer_decision_comment',
          condition: this.userIsNotInterpreter,
          link: this.$router.resolve(this.$makeRoute({
            name: 'BuyerTicketSystem',
            query: {
              view: 'opened',
              page: 1
            }
          })).href,
          linkName: this.$gettext('Create a ticket')
        }, {
          id: 'interpreter_decision_comment'
        }];
      },
      chargeablePayableCells() {
        return [{
          id: 'chargeablePayableValue',
          title: this.$gettext('Charge/Pay')
        }, {
          id: 'customer_feedback_chargeablePayableValue',
          text: this.getHighlightedBooleanValue(this.jobDemanderInfo.chargeable)
        }, {
          id: 'interpreter_feedback_chargeablePayableValue',
          text: this.getHighlightedBooleanValue(this.jobSupplierInfo.payable)
        }, {
          id: 'customer_decision_chargeablePayableValue',
          component: 'option-fields',
          cellClasses: ['decision-tools__cell--center'],
          preselectedValue: this.form.chargeable,
          itemName: 'chargeableOptions',
          onClick: (value) => {
            this.setFormValue({
              key: 'chargeable',
              value
            });
          }
        }, {
          id: 'interpreter_decision_chargeablePayableValue',
          component: 'option-fields',
          cellClasses: ['decision-tools__cell--center'],
          preselectedValue: this.form.payable,
          itemName: 'payableOptions',
          onClick: (value) => {
            this.setFormValue({
              key: 'payable',
              value
            });
          }
        }];
      },
      showedUpCells() {
        return [{
                  id: 'showedUp',
                  title: this.$gettext('Showed up')
                }, {
                  id: 'customer_feedback_showedUp',
                  text: this.getHighlightedBooleanValue(this.jobDemanderInfo.showedUp)
                }, {
                  id: 'interpreter_feedback_showedUp',
                  text: this.getHighlightedBooleanValue(this.jobSupplierInfo.payable)
                }, {
                  id: 'customer_decision_showedUp',
                  component: 'option-fields',
                  cellClasses: ['decision-tools__cell--center'],
                  preselectedValue: this.form.demanderShowedUp,
                  itemName: 'showedUpDemanderOptions',
                  onClick: (value) => {
                    this.setFormValue({
                      key: 'demanderShowedUp',
                      value
                    });
                  }
                },
                {
                  id: 'interpreter_decision_showedUp',
                  component: 'option-fields',
                  cellClasses: ['decision-tools__cell--center'],
                  preselectedValue: this.form.supplierShowedUp,
                  itemName: 'showedUpSupplierOptions',
                  onClick: (value) => {
                    this.setFormValue({
                      key: 'supplierShowedUp',
                      value
                    });
                  }
                }];
      },
      problemsCells() {
        const percentChar = this.$gettext('%');
        const discountText = this.$gettext('Discount');
        return [{
          id: 'problem',
          title: this.$gettext('Assignments were not performed as ordered')
        }, {
          id: 'customer_feedback_problem',
          text: (this.jobDemanderInfo.problems || []).map((problemId) => {
            return helpers.feedbackInfo.feedbackProblem(this, true, problemId);
          }).join('<br>')
        }, {
          id: 'interpreter_feedback_problem',
          text: (this.jobSupplierInfo.problems || []).map((problemId) => {
            return helpers.feedbackInfo.feedbackProblem(this, false, problemId);
          }).join('<br>')
        }, {
          id: 'demander_invoice_discount_percentage',
          component: 'sk-input',
          classes: 'discount-percent__input',
          preselectedValue: this.form.demanderInvoiceDiscountPercentage,
          placeholder: discountText,
          description: percentChar,
          regExp: /[^0-9.]/g,
          errorsName: this.demanderDiscountErrorName,
          onClick: (value) => {
            this.setFormValue({
              key: 'demanderInvoiceDiscountPercentage',
              value: value,
            });
          }
        }, {
          id: 'supplier_invoice_discount_percentage',
          component: 'sk-input',
          classes: 'discount-percent__input',
          preselectedValue: this.form.supplierInvoiceDiscountPercentage,
          placeholder: discountText,
          description: percentChar,
          regExp: /[^0-9.]/g,
          errorsName: this.supplierDiscountErrorName,
          onClick: (value) => {
            this.setFormValue({
              key: 'supplierInvoiceDiscountPercentage',
              value: value,
            });
          }
        }];
      },
      startTimeCells() {
        const jobStartTimeText = `<span class="decision-tools__light-text">${this.$moment(this.jobStartTime).format('HH:mm')}</span>`;

        return [{
          id: 'startTime',
          title: `${this.$gettext('Actual Start')} ${jobStartTimeText}`
        }, {
          id: 'customer_feedback_startTime',
          text: this.getTimeValue(this.jobDemanderInfo.reportedStartTime)
        }, {
          id: 'interpreter_feedback_startTime',
          text: this.getTimeValue(this.jobSupplierInfo.reportedStartTime)
        }, {
          id: 'customer_decision_startTime',
          component: 'sk-timepicker',
          preselectedValue: this.getTimeValue(this.form.demanderStartTime),
          timeStep: this.$timepickerStep(),
          onClick: (value) => {
            this.setFormTimeValue({
              key: 'demanderStartTime',
              value
            });
          }
        }, {
          id: 'interpreter_decision_startTime',
          component: 'sk-timepicker',
          preselectedValue: this.getTimeValue(this.form.supplierStartTime),
          timeStep: this.$timepickerStep(),
          onClick: (value) => {
            this.setFormTimeValue({
              key: 'supplierStartTime',
              value
            });
          }
        }];
      },
      finishTimeCells() {
        return [{
          id: 'finishTime',
          title: `${this.$gettext('Actual Finish')} <span class="decision-tools__light-text">${this.$moment(this.jobFinishTime).format('HH:mm')}</span>`
        }, {
          id: 'customer_feedback_finishTime',
          text: this.getTimeValue(this.jobDemanderInfo.reportedFinishTime)
        }, {
          id: 'interpreter_feedback_finishTime',
          text: this.getTimeValue(this.jobSupplierInfo.reportedFinishTime)
        }, {
          id: 'customer_decision_finishTime',
          component: 'sk-timepicker',
          preselectedValue: this.getTimeValue(this.form.demanderFinishTime),
          timeStep: this.$timepickerStep(),
          onClick: (value) => {
            this.setFormTimeValue({
              key: 'demanderFinishTime',
              value
            });
          }
        }, {
          id: 'interpreter_decision_finishTime',
          component: 'sk-timepicker',
          preselectedValue: this.getTimeValue(this.form.supplierFinishTime),
          timeStep: this.$timepickerStep(),
          onClick: (value) => {
            this.setFormTimeValue({
              key: 'supplierFinishTime',
              value
            });
          }
        }];
      },
      dietCells() {
        return [{
          id: 'diet',
          title: this.$gettext('Diet enabled')
        }, {
          id: 'customer_feedback_diet'
        }, {
          id: 'interpreter_feedback_diet'
        }, {
          id: 'customer_decision_diet',
          component: 'option-fields',
          cellClasses: ['decision-tools__cell--center'],
          preselectedValue: this.form.demanderTravelDiet,
          itemName: 'dietsCustomer',
          onClick: (value) => {
            this.setFormValue({
              key: 'demanderTravelDiet',
              value
            });
          }
        }, {
          id: 'interpreter_decision_diet',
          component: 'option-fields',
          cellClasses: ['decision-tools__cell--center'],
          preselectedValue: this.form.supplierTravelDiet,
          itemName: 'dietsInterpreter',
          onClick: (value) => {
            this.setFormValue({
              key: 'supplierTravelDiet',
              value
            });
          }
        }];
      },
      expanseCells() {
        const unit = this.userCurrencyName;
        const validExpenses = this.expenses ?? [];
        const expensesList = validExpenses?.filter((document) => document.link != null);
        const expensesComponent = expensesList.length && 'documents-list';
        return [{
          id: 'expense',
          title: this.$gettext('Expense')
        }, {
          id: 'customer_feedback_expense',
          text: ''
        }, {
          id: 'interpreter_feedback_expense',
          text: this.getValueWithUnit(this.jobSupplierInfo.travelExpensesAmount, unit),
          file: true,
          documents: expensesList,
          component: expensesComponent,
          cellClasses: ['expenses-file-list'],
          onClick: () => {}
        }, {
          id: 'customer_decision_expense',
          component: 'sk-input',
          preselectedValue: this.form.demanderExpenses,
          placeholder: unit,
          description: unit,
          regExp: /[^0-9.]/g,
          onClick: (value) => {
            this.setFormValue({
              key: 'demanderExpenses',
              value
            });
          }
        }, {
          id: 'interpreter_decision_expense',
          component: 'sk-input',
          preselectedValue: this.form.supplierExpenses,
          placeholder: unit,
          description: unit,
          regExp: /[^0-9.]/g,
          onClick: (value) => {
            this.setFormValue({
              key: 'supplierExpenses',
              value
            });
          }
        }];
      },
      travelTypeCell() {
        return [{
          id: 'delay',
          title: this.$gettext('Travel Method')
        }, {
          id: 'customer_feedback_travelType',
          text: this.jobDemanderInfo.travel_type,
        }, {
          id: 'interpreter_feedback_travelType',
          text: this.jobSupplierInfo.travel_type
        }, {
          id: 'customer_decision_travelType',
          component: 'sk-select',
          itemsList: helpers.feedbackInfo.travelTypeList(this),
          preselectedValue: this.form.demanderTravelType,
          onClick: (value) => {
            this.setFormValue({
              key: 'demanderTravelType',
              value
            });
          }
        }, {
          id: 'interpreter_decision_travelType',
          component: 'sk-select',
          itemsList: helpers.feedbackInfo.travelTypeList(this),
          preselectedValue: this.form.supplierTravelType,
          onClick: (value) => {
            this.setFormValue({
              key: 'supplierTravelType',
              value
            });
          }
        }];
      },
      travelDistanceCells() {
        const unit = this.$gettext('km');

        return [{
          id: 'travelDistance',
          title: this.$gettext('Travel (km)')
        }, {
          id: 'customer_feedback_travelDistance',
          text: this.getValueWithUnit(this.jobDemanderInfo.travelDistance, unit)
        }, {
          id: 'interpreter_feedback_travelDistance',
          text: this.getValueWithUnit(this.jobSupplierInfo.travelDistance, unit)
        }, {
          id: 'customer_decision_travelDistance',
          component: 'sk-input',
          preselectedValue: this.form.demanderDistance,
          placeholder: unit,
          description: unit,
          regExp: /[^0-9.]/g,
          onClick: (value) => {
            this.setFormValue({
              key: 'demanderDistance',
              value
            });
          }
        }, {
          id: 'interpreter_decision_travelDistance',
          component: 'sk-input',
          preselectedValue: this.form.supplierDistance,
          placeholder: unit,
          description: unit,
          regExp: /[^0-9.]/g,
          onClick: (value) => {
            this.setFormValue({
              key: 'supplierDistance',
              value
            });
          }
        }];
      },
      travelTimeCells() {
        const unit = this.$gettext('hour(s)');

        return [{
          id: 'travelTime',
          title: this.$gettext('Travel (time)')
        }, {
          id: 'customer_feedback_travelTime',
          text: this.getValueWithUnit((Math.round(this.jobDemanderInfo.travelTime * 100) / 100).toFixed(2), unit)
        }, {
          id: 'interpreter_feedback_travelTime',
          text: this.getValueWithUnit((Math.round(this.jobSupplierInfo.travelTime * 100) / 100).toFixed(2), unit)
        }, {
          id: 'customer_decision_travelTime',
          component: 'sk-input',
          preselectedValue: this.form.demanderTravelTime,
          placeholder: unit,
          description: unit,
          regExp: /[^0-9.]/g,
          onClick: (value) => {
            this.setFormValue({
              key: 'demanderTravelTime',
              value
            });
          }
        }, {
          id: 'interpreter_decision_travelDistance',
          component: 'sk-input',
          preselectedValue: this.form.supplierTravelTime,
          placeholder: this.$gettext('Time'),
          description: unit,
          regExp: /[^0-9.]/g,
          onClick: (value) => {
            this.setFormValue({
              key: 'supplierTravelTime',
              value
            });
          }
        }];
      },
      chargeAsOptionCells() {
        return [{
          id: 'chargeAsOptions',
          title: this.$gettext('Charge as')
        }, {
          id: 'feedback_chargeAsOptions',
          component: 'charge-as-option-fields',
          cellClasses: ['decision-tools__cell--center'],
          preselectedValue: this.form.actualSessionType,
          itemName: 'chargeAsOptions',
          onClick: (value) => {
            this.setFormValue({
              key: 'actualSessionType',
              value
            });
          }
        }];
      },
      bookingDataCells() {
        const actualCreatedAtMoment = this.form.actualCreatedAt
          ? this.$moment(this.form.actualCreatedAt)
          : this.$moment();

        return [{
          id: 'bookingData',
          title: this.$gettext('Ordered date / time / method')
        }, {
          id: 'feedback_bookingData',
          preselectedDate: actualCreatedAtMoment.format('YYYY-MM-DD'),
          preselectedTime: actualCreatedAtMoment.format('HH:mm'),
          preselectedOption: this.form.bookingMechanism || 'platform',
          component: 'booking-data-option-fields',
          cellClasses: ['decision-tools__cell--center'],
          onDateClick: (value) => {
            this.setFormDateValue({
              key: 'actualCreatedAt',
              value
            });
          },
          onTimeClick: (value) => {
            this.setFormTimeValue({
              key: 'actualCreatedAt',
              value
            });
          },
          onClick: (value) => {
            this.setFormValue({
              key: 'bookingMechanism',
              value
            });
          }
        }];
      },
      bookingFeeCells() {
        return [{
          id: 'bookingFee'
        }, {
          id: 'feedback_bookingFees',
          preselectedValue: this.form.manualFees,
          component: 'booking-fee-option-fields',
          cellClasses: ['decision-tools__cell--center'],
          onClick: (value) => {
            this.setFormManualFee(value);
          }
        }];
      },
      delayCells() {
        return [{
          id: 'delay',
          title: this.$gettext('Delay cause by')
        }, {
          id: 'customer_feedback_delay',
          text: helpers.feedbackInfo.feedbackDelayCausedBy(this, this.jobDemanderInfo.delayCausedBy || '')
        }, {
          id: 'interpreter_feedback_delay',
          text: helpers.feedbackInfo.feedbackDelayCausedBy(this, this.jobSupplierInfo.delayCausedBy || '')
        }, {
          id: 'customer_decision_delay',
          component: 'sk-select',
          itemsList: helpers.feedbackInfo.delayCausedByList(this),
          preselectedValue: this.form.demanderDelayCausedBy,
          onClick: (value) => {
            this.setFormValue({
              key: 'demanderDelayCausedBy',
              value
            });
          }
        }, {
          id: 'interpreter_decision_delay',
          component: 'sk-select',
          itemsList: helpers.feedbackInfo.delayCausedByList(this),
          preselectedValue: this.form.supplierDelayCausedBy,
          onClick: (value) => {
            this.setFormValue({
              key: 'supplierDelayCausedBy',
              value
            });
          }
        }];
      }
    },
    methods: {
      ...mapActions('JobDecisionStore', [
        'fetchJobStatement',
        'updateJobStatement',
        'setError',
      ]),
      ...mapMutations('JobDecisionStore', [
        'setFormManualFee',
        'setFormTimeValue',
        'setFormDateValue',
        'setFormValue',
        'setJobFormValues',
        'setStatementFormValues',
        'clearStore',
        'clearErrors'
      ]),
      validate() {
        this.clearErrors();
        let isValidForm = true;
        if (this.form.demanderInvoiceDiscountPercentage > 100) {
          this.setError({
            fieldName: 'demanderInvoiceDiscountPercentage',
            errorText: this.$gettext('Input 0 - 100')
          });

          isValidForm = false;
        }
        if (this.form.supplierInvoiceDiscountPercentage > 100) {
          this.setError({
            fieldName: 'supplierInvoiceDiscountPercentage',
            errorText: this.$gettext('Input 0 - 100')
          });

          isValidForm = false;
        }
        return isValidForm;
      },
      save() {
        if (this.validate()) {
          this.progressActive = true;
          this.updateJobStatement({
            jobId: this.jobId,
            statementId: this.statementId
          })
            .then(() => {
              this.$store.dispatch('FeedbackStore/getLastUpdatedData', {
                jobId: this.jobId
              })
                .then(() => {
                  this.progressActive = false;
                })
                .catch((error) => {
                  this.progressActive = false;
                  this.$store.commit('ModalStore/setModal', {
                    component: 'error-modal',
                    data: {
                      error
                    }
                  });
                });
            })
            .catch((error) => {
              this.progressActive = false;
              this.$store.commit('ModalStore/setModal', {
                component: 'error-modal',
                data: {
                  error
                }
              });
            });
        }
      },
      getHighlightedBooleanValue(value) {
        return value !== undefined
          ? value
            ? this.$gettext('<span class="decision-tools__highlighted-text">YES</span>')
            : this.$gettext('<span class="decision-tools__highlighted-text">NO</span>')
          : '';
      },
      getTimeValue(value) {
        return value
          ? this.$moment(value).format('HH:mm')
          : '';
      },
      getValueWithUnit(value, unit) {
        return value
          ? `${value} ${unit}`
          : '';
      },
      fetchStatement() {
        this.progressActive = true;
        this.fetchJobStatement({
          jobId: this.jobId,
          statementId: this.statementId
        })
          .then(() => {
            this.setJobFormValues(this.storeJobObj);
            this.setStatementFormValues(this.statement);
            this.progressActive = false;
          })
          .catch((error) => {
            this.progressActive = false;
            this.$store.commit('ModalStore/setModal', {
              component: 'error-modal',
              data: {
                error
              }
            });
          });
      }
    },
    mounted() {
      this.fetchStatement();
    },
    destroyed() {
      this.clearStore();
    }
  };
</script>

<style src="@assets/css/default_accordion.css"></style>

<style>
  .decision-tools__highlighted-text {
    width: 60px;
    padding: 5px;
    border-radius: 4px;
    background-color: #bfe0e1;
    color: #008489;
    font-weight: bold;
    text-align: center;
  }

  .decision-tools__light-text {
    padding-left: 20px;
    color: #7f7f7f;
    font-weight: normal;
  }

  .decision-tools__form-field.sk-input {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
  }

  .decision-tools__form-field.sk-time-picker__wrapper {
    max-width: 65px;
  }

  .decision-tools__form-field.sk-input .sk-input__input-wrapper {
    max-width: 65px;
    margin-right: 5px;
  }

  .decision-tools__form-field.sk-input.discount-percent__input .sk-input__input-wrapper {
    max-width: 80px;
  }

  .decision-tools__form-field.sk-select__wrapper {
    max-width: 140px;
  }
</style>

<style scoped>
  .decision-tools__content {
    padding: 30px 30px 20px;
    background-color: #fff;
  }

  .decision-tools__table-wrapper {
    overflow: auto;
  }

  .decision-tools__table {
    width: 1166px;
  }

  .decision-tools__header {
    overflow: auto;
  }

  .decision-tools__table-row {
    display: flex;
    align-items: stretch;
    border-bottom: 1px solid #e9e9ee;
  }

  .decision-tools__table-row--margin-top {
    margin-top: 20px;
  }

  .decision-tools__table-row:last-child,
  .decision-tools__table-row--no-borders {
    border-bottom: none;
  }

  .decision-tools__table-row--no-row-borders .decision-tools__table-cell--row-header .decision-tools__cell {
    border-right: none;
  }

  .decision-tools__form-field {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .decision-tools__table-cell {
    width: 100%;
  }

  .decision-tools__table-cell--col-header {
    font-weight: bold;
  }

  .decision-tools__table-cell--col-header:first-child,
  .decision-tools__table-cell:first-child {
    max-width: 220px;
  }

  .decision-tools__table-cell--row-header .decision-tools__cell {
    padding: 10px 10px 10px 0;
    border-right: 1px solid #e9e9ee;
    font-weight: bold;
  }

  .decision-tools__cell {
    display: block;
    width: 100%;
    height: 100%;
    min-height: 45px;
    padding: 10px;
  }

  .decision-tools__cell--center {
    display: flex;
    align-items: center;
  }

  .expense-list {
    height: auto;
  }

  .expenses-file-list {
    height: auto;
    padding: 0 10px;
  }
</style>
