(<template>
  <div class="option-fields">
    <sk-datepicker :preselected-value="preselectedDate"
                   :full-calendar="true"
                   :default-value="'--/--/----'"
                   :prevent-call="true"
                   :no-description="true"
                   class="option-fields__field option-fields__field--datepicker"
                   @changeday="setDate" />
    <sk-timepicker :preselected-value="preselectedTime"
                   :auto-scroll="true"
                   :no-description="true"
                   :default-value="'--:--'"
                   :time-step="1"
                   :mob-numeric-keyboard="true"
                   class="option-fields__field option-fields__field--timepicker"
                   @timepickervaluechanged="setTime" />
    <div class="option-fields__radios-wrapper">
      <sk-radio v-for="mechanism in bookingMechanismList"
                :key="mechanism.key"
                :preselected-value="preselectedOption"
                :item-text="mechanism.text"
                :item-value="mechanism.key"
                :item-name="'booking mechanism'"
                class="option-fields__field option-fields__field--radio"
                @radiochanged="setOption" />
    </div>
  </div>
</template>)

<script>
  import helpers from '@/helpers';

  export default {
    props: {
      preselectedDate: {
        type: [String, Object],
        default: ''
      },
      preselectedTime: {
        type: [String, Object],
        default: ''
      },
      preselectedOption: {
        type: String,
        default: ''
      }
    },
    computed: {
      bookingMechanismList() {
        return helpers.getJobInfo.jobBookingMechanismList(this);
      }
    },
    methods: {
      setDate(value) {
        this.$emit('datechanged', value);
      },
      setTime(value) {
        this.$emit('timechanged', value);
      },
      setOption(value) {
        this.$emit('optionchanged', value);
      },
    }
  };
</script>

<style>
  .option-fields .sk-date-picker__calendar,
  .option-fields .sk-time-picker__list {
    top: -2px;
    margin-top: 0;
    transform: translate(0, -100%);
  }
</style>

<style scoped>
  .option-fields {
    display: flex;
    align-items: center;
    margin: 0 -10px;
  }

  .option-fields__radios-wrapper {
    margin-left: 5px;
  }

  .option-fields__field {
    margin: 0 10px;
  }

  .option-fields__field--datepicker {
    width: 95px;
  }

  .option-fields__field--timepicker {
    width: 65px;
  }
</style>
